const MLogisticsManager = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lm.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column pl-1',
    active_icon: 'fad fa-objects-column pl-1'
  },
  {
    title: 'Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lm.purchase-orders.approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cart-circle-check',
    active_icon: 'fad fa-cart-circle-check'
  }
]

export default MLogisticsManager
