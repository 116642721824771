const MAdmin = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Accounts',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.accounts'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Users',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.accounts.users'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-user-visor',
    active_icon: 'fad fa-user-visor'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.maintenances'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Warehouses',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.warehouses'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-warehouse-full',
    active_icon: 'fad fa-warehouse-full'
  },
  {
    title: 'Locations',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.locations'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-store',
    active_icon: 'fad fa-store'
  },
  {
    title: 'Groups',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.groups'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cube',
    active_icon: 'fad fa-cube'
  },
  {
    title: 'Categories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.categories'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cubes',
    active_icon: 'fad fa-cubes'
  },
  {
    title: 'Products',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.products'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cubes-stacked',
    active_icon: 'fad fa-cubes-stacked'
  }
]

export default MAdmin
