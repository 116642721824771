<template>
  <b-collapse
    :id="idName"
    tag="ul"
    :class="className"
    :visible="open"
    :accordion="accordianName"
  >
    <li
      v-for="(item,index) in items"
      :key="index"
      :class="[item.class_name, {
        'p-0': !hideMenuTitle,
        'iq-menu-title': item.is_heading,
        'active': activeLink(item) && item.children || activeLink(item)
      }]"
    >
      <i
        v-if="item.is_heading && hideMenuTitle"
        class="ri-subtract-line"
      />

      <span
        v-if="item.is_heading && hideMenuTitle"
        v-text="item.title"
      />

      <a
        v-if="item.children && !item.is_heading"
        v-b-toggle="item.name"
        :class="`cursor-pointer iq-waves-effect ${item.class_name} ${activeLink(item) && item.children ? 'active' : (activeLink(item) ? 'active' : '')}`"
      >
        <i
          v-if="item.is_icon_class"
          :class="activeLink(item) && item.active_icon ? item.active_icon : item.icon"
        />
        <template
          v-else
          v-text="activeLink(item) && item.active_icon ? item.active_icon : item.icon"
        />
        <span v-text="item.title" />
        <i
          v-if="item.children"
          class="ri-arrow-right-s-line iq-arrow-right"
        />
        <small
          v-if="hideMenuTitle"
          :class="item.append_class"
          v-text="item.append"
        />
      </a>
      <router-link
        v-if="!item.children && !item.is_heading"
        v-b-toggle="item.name"
        :to="item.link"
        :class="`iq-waves-effect ${item.class_name} ${activeLink(item) && item.children ? 'active' : (activeLink(item) ? 'active' : '')}`"
      >
        <i
          v-if="item.is_icon_class"
          :class="activeLink(item) && item.active_icon ? item.active_icon : item.icon"
        />
        <template
          v-else
          v-text="activeLink(item) && item.active_icon ? item.active_icon : item.icon"
        />
        <span v-text="item.title" />
        <i
          v-if="item.children"
          class="ri-arrow-right-s-line iq-arrow-right"
        />
        <small
          v-if="hideMenuTitle"
          :class="item.append_class"
          v-text="item.append"
        />
      </router-link>
      <List
        v-if="item.children"
        :items="item.children"
        :sidebar-group-title="hideMenuTitle"
        :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))"
        :id-name="item.name"
        :accordian-name="`sidebar-accordion-${item.class_name}`"
        :class-name="`iq-submenu ${item.class_name}`"
      />
    </li>
  </b-collapse>
</template>
<script>
import List from './CollapseMenu'
import { core } from '@/config/pluginInit'

export default {
  name: 'List',

  components: {
    List
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    className: { type: String, default: 'iq-menu' },
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' },
    sidebarGroupTitle: { type: Boolean, default: true }
  },

  computed: {
    hideMenuTitle () {
      return this.sidebarGroupTitle
    }
  },

  methods: {
    activeLink (item) {
      return core.getActiveLink(item, this.$route.name)
    }
  }
}
</script>
