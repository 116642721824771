const MUser = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column pl-1',
    active_icon: 'fad fa-objects-column pl-1'
  },
  {
    title: 'Purchase Orders',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.purchase-orders'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.purchase-orders.requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cart-circle-plus',
    active_icon: 'fad fa-cart-circle-plus'
  },
  {
    title: 'Receiving',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.purchase-orders.receiving'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cart-circle-arrow-down',
    active_icon: 'fad fa-cart-circle-arrow-down'
  },
  {
    title: 'Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.purchase-orders.approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-cart-circle-check',
    active_icon: 'fad fa-cart-circle-check'
  },
  {
    title: 'SAP',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.sap'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Posting',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.sap.posting'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-code-compare',
    active_icon: 'fad fa-code-compare'
  }
]

export default MUser
